import { useContract, useMetadata } from '@thirdweb-dev/react';
import { Col, notification, Row, Spin } from 'antd';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContractCard from '../../components/contract-card/ContractCard';
import CollectionForm from '../../components/forms/CollectionForm';
import FormPageLayout from '../../components/Layouts/FormPageLayout';
import useCollection from '../../hooks/useCollection';
import CollectionsService from '../../services/collection.service';
import { ICollectionDTO } from '../../types/ICollection';

const contractNotFoundMessage = 'Contract not found';
const collectionSuccessMessage = 'Collection updated!';

const UpdateCollection: FC = () => {
    const { collectionId } = useParams();

    const { data: collection, isLoading: isCollectionLoading } = useCollection(collectionId);

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (data: ICollectionDTO) => {
        try {
            if (collectionId) {
                setIsLoading(true);
                await CollectionsService.updateCollection(collectionId, data);
                notification.success({
                    message: collectionSuccessMessage,
                });
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }

    const { contract, isLoading: isContractLoading } = useContract(collection?.contractAddress);
    const { data: metadata, isLoading: isMetadataLoading } = useMetadata(contract);
    const isContractNotFound = !contract && !isContractLoading;

    return (
        <FormPageLayout
            backButtonLabel="Collections"
            justify="start"
        >
            <Row justify="space-between" gutter={16}>
                <Col span={12}>
                    <CollectionForm
                        collection={collection}
                        isLoading={isLoading || isCollectionLoading}
                        onSubmitValues={handleSubmit}
                    />
                </Col>
                <Col span={12}>
                    <Row justify="center">
                        <Spin spinning={!isContractNotFound && isMetadataLoading}>
                            <ContractCard
                                image={metadata?.image}
                                name={metadata?.name}
                                description={isContractNotFound ? contractNotFoundMessage : metadata?.description}
                            />
                        </Spin>
                    </Row>
                </Col>
            </Row>
        </FormPageLayout>
    );
};

export default UpdateCollection;
import { Card, Row, RowProps } from "antd";
import React, { FC, ReactNode } from "react";
import BackButton from "../BackButton";

interface FormPageLayoutProps {
    children: ReactNode;
    backButtonLabel: ReactNode;
    justify?: RowProps['justify'];
}

const FormPageLayout: FC<FormPageLayoutProps> = ({ children, backButtonLabel, justify = 'center' }) => {
    return (
        <>
            <Row>
                <BackButton>{backButtonLabel}</BackButton>
            </Row>
            <Row justify={justify}>
                <Card
                    style={{ width: justify === 'center' ? 'auto' : '100%' }}
                    bordered={false} 
                    bodyStyle={{ minWidth: '800px'}}
                >
                    {children}
                </Card>
            </Row>
        </>
    );
}

export default FormPageLayout;
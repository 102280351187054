import { Button, Checkbox, Form, Input, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { FC, useEffect } from "react";
import { validateMessages } from "../../common/constants/validateMessages";
import { IGameDTO, IGame } from "../../types/IGame";

interface GameFormProps {
    game?: IGame | null;
    isLoading: boolean;
    onSubmitValues: ((data: IGameDTO) => void);
}

const GameForm: FC<GameFormProps> = ({ isLoading, game, onSubmitValues }) => {
    const [form] = useForm<IGameDTO>();

    useEffect(() => {
        if (game) {
            form.setFieldsValue(game);
        }
    }, [game, form]);

    const onFinish = (values: IGameDTO) => {
        onSubmitValues(values);
    }

    return (
        <Spin spinning={isLoading}>
            <Form<IGameDTO>
                form={form}
                onFinish={onFinish}
                validateMessages={validateMessages}
                initialValues={{
                    isActive: false,
                }}
            >   
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true }]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Code"
                    name="code"
                    rules={[{ required: true }]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Active"
                    name="isActive"
                    valuePropName="checked"
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}

export default GameForm;
import { useEffect, useState } from "react";
import CollectionsService from "../services/collection.service";
import { ICollection } from "../types/ICollection";

const useCollection = (id?: ICollection['_id']) => {
    const [collection, setCollection] = useState<ICollection | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<unknown | null>(null);

    useEffect(() => {
        let ignore = false;

        async function startFetching(id: ICollection['_id']) {
            try {
                setIsLoading(true);
                const collection = await CollectionsService.getCollection(id);
                if (!ignore) {
                    setCollection(collection);
                }
            } catch (e) {
                console.error(e);
                setError(e);
            } finally {
                setIsLoading(false);
            }
        }

        if (id)
            startFetching(id);

        return () => {
            ignore = true;
        };
    }, [id]);

    return { data: collection, isLoading, error };
}

export default useCollection;
import React from 'react';
import { Card } from "antd";

interface Props {
    name?: string;
    description?: string;
    image?: string;
}

const ContractCard: React.FC<Props> = ({ image, name, description }) => {
    return (
        <Card
            title="CONTRACT DATA"
            style={{ width: 340 }}
            cover={image && <img style={{ width: '100%' }} src={image} />}
        >
            <Card.Meta title={name} description={description} />
        </Card>
    )
}

export default ContractCard;